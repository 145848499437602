import AudioHowl from '@phoenix7dev/play-music';
import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import { ISongs } from '../config';
import {
  bonusesId,
  BonusStatus,
  Cascade,
  EventTypes,
  GameMode,
  ISettledBet,
  IUserBalance,
  MessageBannerProps,
  reelSets,
  UserBonus,
} from '../global.d';
import {
  setBetAmount,
  setBottomContainerTotalWin,
  setBrokenBuyFeature,
  setBrokenBuyFeatureBonusId,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsErrorMessage,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsTimeoutErrorMessage,
  setIsTransitionStarted,
  setLastRegularWinAmount,
  setPrevGameMode,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { getUserBonuses, getUserGql, isStoppedGql, slotBetGql } from '../gql/query';
import {
  delayedAction,
  formatNumber,
  getBackgroundLoopAudioByGameMode,
  getCascadeColumns,
  getFreeSpinsTitleByGameMode,
  getGameModeByBonusId,
  getSpinResult,
  getStateByMode,
  getWinStage,
  handleBackgroundAudio,
  isBuyFeatureEnabled,
  isBuyFeatureMode,
  isFreeRoundBonusMode,
  isFreeSpinMode,
  isFreeSpinsBonus,
  isMobileDevice,
  isMultipliersAvailable,
  isRegularMode,
  nextTick,
  normalizeCoins,
  showCurrency,
} from '../utils';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import BuyFeaturePopup from './buyFeature/buyFeaturePopup';
import BuyFeaturePopupConfirm from './buyFeature/buyFeaturePopupConfirm';
import { CascadeAnimation } from './cascade/cascadeAnimation';
import { CharactersContainer } from './characters/characters';
import {
  eventManager,
  FREE_SPINS_TIME_OUT_BANNER,
  MULTIPLIER_MOVE_TIME,
  REELS_AMOUNT,
  SlotMachineState,
  WinStages,
} from './config';
import AutoplayBtn from './controlButtons/autoplayBtn';
import BetBtn from './controlButtons/betBtn';
import MenuBtn from './controlButtons/menuBtn';
import SoundBtn from './controlButtons/soundBtn';
import SpinBtn from './controlButtons/spinBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import { ISlotData } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import {
  additionalPosition,
  INIT_SUBTEXT_POSITION_Y,
  titlePosition,
} from './messageBanner/config';
import {
  mobileBtnStyle,
  mobileSubtitleStyle,
  mobileTitleStyle,
  mobileWinSubtitleStyle,
  mobileWinTitleStyle,
  subtitleStyle,
  titleStyle,
  winSubtitleStyle,
  winTitleStyle,
} from './messageBanner/textStyles';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import { RETRIGGER_MESSAGE_DELAY_DURATION } from './retrigger/config';
import RetriggerMessage from './retrigger/retriggerMessage';
import SafeArea from './safeArea/safeArea';
import Slot from './slot/slot';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';
import WinSlotsContainer from './winAnimations/winSlotsContainer';
import { FreeRoundsPopup } from './popups/freeRoundsPopup';
import { FreeRoundsEndPopup } from './popups/freeRoundsPopupEnd';
import { PopupTypes } from './popups/d';
import { PopupController } from './popups/PopupController';

const getUserActiveBonuses = () => {
  return client.query<{ userBonuses: UserBonus[] }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });
};

class SlotMachine {
  private application: PIXI.Application;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    application: PIXI.Application,
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(
      application,
      slotData,
      isSpinInProgressCallback,
      isSlotBusyCallback,
    );
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public gameView: GameView;

  public reelsContainer: ReelsContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public menuBtn: MenuBtn;

  public soundBtn: SoundBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  private constructor(
    application: PIXI.Application,
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    // window.PIXI = PIXI;
    // (globalThis as unknown as { __PIXI_APP__: unknown }).__PIXI_APP__ = this.application;
    this.initEventListeners();
    this.application.stage.sortableChildren = true;
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    let { startPosition } = slotData.settings;
    let reelSet;
    if (setBrokenBuyFeature()) {
      [reelSet] = slotData.reels;
      this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    } else {
      startPosition = setUserLastBetResult().id
        ? setUserLastBetResult().result.reelPositions
        : slotData.settings.startPosition;
      reelSet = setUserLastBetResult().id
        ? slotData.reels.find(
          (reelSet) => reelSet.id === setUserLastBetResult().reelSetId,
        )!
        : slotData.reels[0];
      this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    }
    this.miniPayTableContainer = new MiniPayTableContainer(
      slotData.icons,
      this.getSlotById.bind(this),
    );
    this.miniPayTableContainer.setSpinResult(
      getSpinResult({
        reelPositions: startPosition.slice(0, 6),
        reelSet,
        icons: slotData.icons,
      }),
    );
    this.gameView = this.initGameView(slotData);
    this.menuBtn = new MenuBtn();
    this.soundBtn = new SoundBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    // popups
    const freeRoundsPopup = new FreeRoundsPopup();
    const freeRoundsEndPopup = new FreeRoundsEndPopup();

    PopupController.the.registerPopup(PopupTypes.FREE_ROUNDS, freeRoundsPopup);
    PopupController.the.registerPopup(PopupTypes.FREE_ROUNDS_END, freeRoundsEndPopup);

    this.initPixiLayers();
    this.application.stage.addChild(this.menuBtn);
    this.application.stage.addChild(this.soundBtn);
    this.application.stage.addChild(this.turboSpinBtn);
    this.application.stage.addChild(this.spinBtn);
    this.application.stage.addChild(this.betBtn);
    this.application.stage.addChild(this.autoplayBtn);
    this.application.stage.addChild(freeRoundsPopup);
    this.application.stage.addChild(freeRoundsEndPopup);
  }

  // todo impl
  private async onBrokenGame(): Promise<void> {
    const gameMode = getGameModeByBonusId(setCurrentBonus().bonusId);
    handleBackgroundAudio(GameMode.REGULAR, gameMode);
    if (gameMode === GameMode.FREE_ROUND_BONUS) {
      this.startFreeroundBonus();
    } else {
      setIsFreeSpinsWin(true);
      setGameMode(gameMode);
      eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, true);
      if(!setBottomContainerTotalWin()) {
        eventManager.emit(EventTypes.HIDE_WIN_LABEL);
      } else {
        eventManager.emit(
          EventTypes.UPDATE_TOTAL_WIN_VALUE,
          setBottomContainerTotalWin(),
        );
      }
      this.setState(SlotMachineState.IDLE);
    }
  }

  private initPixiLayers() {
    this.application.stage.addChild(
      new Background(),
      new Backdrop(
        EventTypes.OPEN_BUY_FEATURE_POPUP_BG,
        EventTypes.CLOSE_BUY_FEATURE_POPUP_BG,
      ),
      this.initSafeArea(),
      new BottomContainer(),
      new CharactersContainer(),
      new FadeArea(),
    );
  }

  private initSafeArea(): SafeArea {
    const safeArea = new SafeArea();
    safeArea.addChild(this.gameView);
    return safeArea;
  }

  private initGameView(slotData: ISlotData): GameView {
    const gameView = new GameView({
      winSlotsContainer: new WinSlotsContainer(),
      reelsBackgroundContainer: new ReelsBackgroundContainer(),
      reelsContainer: this.reelsContainer,
      winLabelContainer: new WinLabelContainer(),
      winCountUpMessage: new WinCountUpMessage(),
      miniPayTableContainer: this.miniPayTableContainer,
    });
    gameView.interactive = true;
    gameView.on('mousedown', () => this.skipAnimations());
    gameView.on('touchstart', () => this.skipAnimations());

    if (isBuyFeatureEnabled(slotData.clientSettings.features)) {
      this.initBuyFeature(gameView);
    }

    return gameView;
  }

  private initBuyFeature(view: GameView): void {
    view.addChild(
      new BuyFeatureBtn(),
      new BuyFeaturePopup(),
      new Backdrop(
        EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP_BG,
        EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP_BG,
      ),
      new BuyFeaturePopupConfirm(),
    );
  }

  private initEventListeners(): void {
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
      if (setBrokenBuyFeature()) {
        nextTick(() => {
          // todo replace with appropriate dynamic bonusId
          eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND, {
            id: setBrokenBuyFeatureBonusId(),
          });
          setBrokenBuyFeature(false);
          setBrokenBuyFeatureBonusId(null);
        });
      }
      if (setBrokenGame()) {
        this.onBrokenGame();
      }
    });
    eventManager.addListener(
      EventTypes.RESET_SLOT_MACHINE,
      this.resetSlotMachine.bind(this),
    );
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(
      EventTypes.SLOT_MACHINE_STATE_CHANGE,
      this.onStateChange.bind(this),
    );
    eventManager.addListener(
      EventTypes.REELS_STOPPED,
      this.onReelsStopped.bind(this),
    );
    eventManager.addListener(
      EventTypes.COUNT_UP_END,
      this.onCountUpEnd.bind(this),
    );
    eventManager.addListener(
      EventTypes.THROW_ERROR,
      this.handleError.bind(this),
    );
    eventManager.addListener(
      EventTypes.CHANGE_MODE,
      this.onChangeMode.bind(this),
    );
    eventManager.addListener(
      EventTypes.NEXT_CASCADE,
      this.nextCascade.bind(this),
    );
    eventManager.addListener(
      EventTypes.START_BUY_FEATURE_ROUND,
      this.startBuyFeature.bind(this),
    );
    eventManager.on(EventTypes.FREE_ROUND_BONUS_EXPIRED, () => {
      setBrokenGame(false);
      setIsTransitionStarted(true);
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.REGULAR,
        reelPositions: setUserLastBetResult().result.reelPositions,
        reelSetId: setUserLastBetResult().reelSetId,
      });
    });
    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      handleBackgroundAudio(GameMode.REGULAR, setGameMode());
      if (setIsDuringBigWinLoop()) {
        AudioHowl.play({ type: ISongs.BigWin_Loop });
      }
    });
  }

  public throwTimeoutError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsTimeoutErrorMessage(true);
    }
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS);
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    endBonus?: boolean;
  }) {
    setPrevGameMode(setGameMode());
    setGameMode(settings.mode);
    handleBackgroundAudio(setPrevGameMode(), setGameMode());
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);

    const reelSet = setSlotConfig().reels.find(
      (reels) => reels.id === settings.reelSetId,
    );
    const spinResult = getSpinResult({
      reelPositions: settings.reelPositions.slice(0, 6),
      reelSet: reelSet!,
      icons: setSlotConfig().icons,
    });
    this.miniPayTableContainer.setSpinResult(spinResult);

    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      if (this.nextResult) {
        eventManager.emit(
          EventTypes.UPDATE_USER_BALANCE,
          this.nextResult?.balance.settled,
        );
      } else {
        client
          .query<{ user: IUserBalance }>({
            query: getUserGql,
            fetchPolicy: 'network-only',
          })
          .then((userBalance) => {
            eventManager.emit(EventTypes.UPDATE_USER_BALANCE, userBalance.data.user.balance);
          });
      }
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber(
          setCurrency(),
          normalizeCoins(setBottomContainerTotalWin()),
          showCurrency(setCurrency()),
        ),
      );
      eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
      eventManager.emit(
        EventTypes.DISABLE_BUY_FEATURE_BTN,
        setIsContinueAutoSpinsAfterFeature(),
      );
      eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, false);
      this.setState(SlotMachineState.IDLE);
    } else if (isFreeSpinMode(settings.mode)) {
      eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, true);
      eventManager.emit(EventTypes.SET_WIN_LABEL_TEXT, 'total win');
      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
      if (setBottomContainerTotalWin() > 0) {
        eventManager.emit(
          EventTypes.UPDATE_TOTAL_WIN_VALUE,
          setBottomContainerTotalWin(),
        );
      }
      if (
        settings.mode === GameMode.DEVIL ||
        settings.mode === GameMode.ANGEL_AND_DEVIL
      ) {
        eventManager.emit(EventTypes.UPDATE_MULTIPLIER_VALUE, 1);
      }

      const nextState = getStateByMode(settings.mode);
      if (!isFreeSpinMode(setPrevGameMode())) {
        eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
          text: i18n.t(getFreeSpinsTitleByGameMode(settings.mode)),
          spins: `${setCurrentBonus().rounds}`,
          currentSpin: '0',
        });
      } else {
        eventManager.emit(EventTypes.UPDATE_FREE_SPINS_TITLE, settings.mode);
        this.updateFreeSpinsAmount(
          setCurrentBonus().currentRound,
          setCurrentBonus().rounds,
        );
      }
      if (!setIsContinueAutoSpinsAfterFeature()) {
        eventManager.emit(EventTypes.CREATE_FREE_SPIN_MESSAGE_BANNER, {
          title: getFreeSpinsTitleByGameMode(setGameMode()),
          titlePosition: 270,
          mobileTitlePosition: 200,
          titleStyles: titleStyle,
          mobileTitleStyle,
          mobileSubtitleStyle,
          subtitle: !isFreeSpinMode(setPrevGameMode())
            ? 'freeSpinsMessageBannerSubtitle'
            : 'freeSpinsMessageBannerAddedSubtitle',
          subtitlePosition: INIT_SUBTEXT_POSITION_Y,
          mobileSubtitlePosition: 490,
          subtitleStyles: subtitleStyle,
          additionalPosition,
          btnText: 'freeSpinsMessageBannerBtnText',
          mobileBtnStyle,
          mobileSubtitleBorderWidth: 350,
          mobileSubtitleBorderHeight: 350,
          subtitleBorderWidth: 1000,
          subtitleBorderHeight: 250,
          callback: () => {
            this.setState(nextState);
          },
        });
      } else {
        this.setState(nextState);
      }
    } else if (settings.mode === GameMode.FREE_ROUND_BONUS) {
      eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, false);
      eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
      if (!settings?.endBonus && setFreeRoundsBonus().isActive) {
        setCurrentBonus({ ...setFreeRoundsBonus() });
      }
      setIsFreeSpinsWin(false);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds - setCurrentBonus().currentRound);
      setCoinValue(setCurrentBonus().coinValue);
      setCoinAmount(setCurrentBonus().coinAmount);
      setBetAmount(setCurrentBonus().coinAmount * setSlotConfig().lineSets[0].coinAmountMultiplier);
      eventManager.emit(EventTypes.UPDATE_BET);
      setIsSlotBusy(false);

      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
      if (setBottomContainerTotalWin() > 0) {
        setTimeout(() => {
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
        }, 0);
      }
      eventManager.once(EventTypes.START_FREE_ROUND_BONUS, () => {
        this.setState(SlotMachineState.IDLE);
      });
      eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
      if (settings?.endBonus && setFreeRoundsBonus().rounds - setFreeRoundsBonus().currentRound > 0) {
        setCurrentBonus({ ...setCurrentBonus(), rounds: setFreeRoundsBonus().rounds, currentRound: setFreeRoundsBonus().currentRound });
        eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds - setCurrentBonus().currentRound);
        setStressful({
          show: true,
          type: 'network',
          message: i18n.t('errors.OPERATOR.INVALID_BONUS'),
          callback: () => {
            setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive: false });
            setCurrentBonus({ ...setCurrentBonus(), isActive: false });
            PopupController.the.openPopup(PopupTypes.FREE_ROUNDS_END, { isExpired: true });
          },
        });
      } else if (setCurrentBonus().rounds - setCurrentBonus().currentRound === 0) {
        this.setState(SlotMachineState.IDLE);
      } else if (!setFreeRoundsBonus().isActive || !setFreeRoundsBonus().currentRound) {
        PopupController.the.openPopup(PopupTypes.FREE_ROUNDS);
      } else {
        setCurrentBonus({ ...setCurrentBonus(), isActive: true });
        eventManager.emit(EventTypes.START_FREE_ROUND_BONUS);
      }
    }
  }

  private startBuyFeature(buyBonus: { bonusId: string }): void {
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: getGameModeByBonusId(buyBonus.bonusId),
      reelPositions: [0, 0, 0, 0, 0, 0],
      reelSetId: reelSets[getGameModeByBonusId(buyBonus.bonusId)],
    });
  }

  // todo implement start free spins
  private startFreeSpins(bonusId: string): void {
    setIsFreeSpinsWin(true);
    const mode = getGameModeByBonusId(bonusId);
    setIsTransitionStarted(true);
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode,
      reelPositions: [0, 0, 0, 0, 0, 0],
      reelSetId: reelSets[mode],
    });
  }

  // todo implement start free spins
  private async endFreeSpins(): Promise<void> {
    const res = await client.query<{
      userBonuses: UserBonus[];
    }>({
      query: getUserBonuses,
      variables: { input: { id: setCurrentBonus().id } },
      fetchPolicy: 'network-only',
    });
    const { betId } = res.data.userBonuses[0];
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: betId } },
      fetchPolicy: 'network-only',
    });
    const { reelPositions, winCountAmount, reelSetId } = {
      reelPositions: bet.data.bet.result.reelPositions,
      winCountAmount: bet.data.bet.result.winCoinAmount,
      reelSetId: bet.data.bet.reelSetId,
    };

    const isFreeRoundsBonus = setFreeRoundsBonus().isActive;
    let frbBonus: UserBonus;
    if (isFreeRoundsBonus) {
      setFreeRoundsTotalWin(setFreeSpinsTotalWin() + setFreeRoundsTotalWin());
      setBottomContainerTotalWin(setFreeRoundsTotalWin());
      const bonuses = await getUserActiveBonuses();
      // const bonuses: UserBonus = {
      //   ...(setCurrentBonus() as UserBonus),
      //   isActive: true,
      //   gameMode: GameMode.FREE_ROUND_BONUS,
      //   currentRound: 0,
      //   rounds: 5,
      //   totalWinAmount: 0,
      //   isFreeBet: true,
      //   coinAmount: 1,
      //   coinValue: 100,
      //   id: '6a3a99d5-8988-488a-8af3-1d5ed72716f1',
      //   bonusId: '6a3a99d5-8988-488a-8af3-1d5ed72716f1',
      // };
      frbBonus = bonuses.data.userBonuses.find(
        (e) => e.bonusId === bonusesId[GameMode.FREE_ROUND_BONUS],
      ) as UserBonus;
      if (!frbBonus) {
        eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
      }
    }

    setLastRegularWinAmount(setFreeSpinsTotalWin());
    this.playTotalWinAudio();
    const callback = () => {
      setIsTransitionStarted(true);
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: isFreeRoundsBonus ? GameMode.FREE_ROUND_BONUS : GameMode.REGULAR,
        reelSetId,
        reelPositions,
        endBonus: !frbBonus
      });
    };
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    setBrokenGame(false);
    const messageBannerProps: Partial<MessageBannerProps> = {
      title: 'freeSpinsMessageBannerWinText',
      titleStyles: winTitleStyle,
      mobileTitleStyle: mobileWinTitleStyle,
      titlePosition: 380,
      mobileTitlePosition: 375,
      subtitle: `${formatNumber(
        setCurrency(),
        normalizeCoins(setFreeSpinsTotalWin()),
        showCurrency(setCurrency()),
      )}`,
      subtitleStyles: winSubtitleStyle,
      mobileSubtitleStyle: mobileWinSubtitleStyle,
      subtitlePosition: 575,
      mobileSubtitlePosition: 575,
      mobileSubtitleBorderWidth: 550,
      mobileSubtitleBorderHeight: 450,
      subtitleBorderWidth: 500,
      subtitleBorderHeight: 250,
    };
    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        ...messageBannerProps,
        callback,
        preventDefaultDestroy: true,
      });
    } else {
      const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
      delay.addOnComplete(() => {
        callback();
      });
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        ...messageBannerProps,
        onInitCallback: () => delay.start(),
      });
    }
  }

  private playTotalWinAudio(): void {
    AudioHowl.fadeOut(0, getBackgroundLoopAudioByGameMode(setGameMode()));
    if (setGameMode() === GameMode.ANGEL) {
      AudioHowl.play({ type: ISongs.TotalWinBanner_ANGEL, stopPrev: true });
    }
    if (setGameMode() === GameMode.DEVIL) {
      AudioHowl.play({ type: ISongs.TotalWinBanner_DEVIL, stopPrev: true });
    }
    if (setGameMode() === GameMode.ANGEL_AND_DEVIL) {
      AudioHowl.play({ type: ISongs.TotalWinBanner_AandD, stopPrev: true });
    }
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('error_general'),
      });
    }
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[5].cascadeAnimation
      ?.getWaiting()
      .removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(current: number, total: number): void {
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      total,
      current,
    );
  }

  private updateFreeRoundsAmount(current: number, total: number): void {
    eventManager.emit(
      EventTypes.UPDATE_FREE_ROUNDS_LEFT,
      total - current
    );
  }

  public spin(): void {
    if (this.state === SlotMachineState.SPIN) {
      if (this.isReadyForStop) {
        this.stopSpin();
      } else {
        this.isStopped = true;
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      this.isReadyForStop = false;
      this.skipAnimations();
      this.isStopped = false;
      this.nextResult = null;
      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation();
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      spinAnimation.start();
    }
    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i];
      const cascadeAnimation: CascadeAnimation = reel.createCascadeAnimation();
      if (i === REELS_AMOUNT - 1) {
        cascadeAnimation.getWaiting().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              getCascadeColumns({
                reelPositions: this.nextResult.bet.result.reelPositions,
                layout: this.nextResult.bet.reelSet.layout,
                cascades: this.nextResult.bet.data.features.cascade,
              }),
              this.isStopped,
            );
          }
        });
        cascadeAnimation.getWaiting().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i].isPlaySoundOnStop = true;
      animationGroup.addAnimation(cascadeAnimation);
    }

    return animationGroup;
  }

  private isWinFreeSpins(): boolean {
    const bonus = this.nextResult!.bet.data.bonuses.find(
      (bonus) => bonus.bonusId !== bonusesId[GameMode.FREE_ROUND_BONUS],
    );
    return !!bonus;
  }

  private handleFreeSpinWin(): void {
    const mode = setGameMode();
    const bonus = this.nextResult!.bet.data.bonuses.find((e) => isFreeSpinsBonus(e.bonusId))!;
    if (isBuyFeatureMode(mode) || isRegularMode(mode)) {
      if (mode === GameMode.FREE_ROUND_BONUS) {
        setFreeRoundsBonus({ ...setCurrentBonus() });
        setFreeRoundsTotalWin(setBottomContainerTotalWin());
        setBottomContainerTotalWin(setBottomContainerTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        setFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
        eventManager.emit(
          EventTypes.UPDATE_TOTAL_WIN_VALUE,
          setBottomContainerTotalWin()
        );
      } else {
        setBottomContainerTotalWin(this.nextResult!.bet.result.winCoinAmount);
        setFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber(setCurrency(), normalizeCoins(this.nextResult!.bet.result.winCoinAmount), showCurrency(setCurrency())),
        );
      }
      setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      setCurrentBonus({
        ...bonus,
        isActive: true,
        currentRound: 0,
      });
      this.startFreeSpins(bonus.bonusId);
      this.setState(SlotMachineState.IDLE);
    }
    if (isFreeSpinMode(mode)) {
      // on retrigger
      if (setCurrentBonus().bonusId === bonus.bonusId) {
        setFreeSpinsTotalWin(setFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        setBottomContainerTotalWin(setBottomContainerTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        eventManager.emit(
          EventTypes.UPDATE_TOTAL_WIN_VALUE,
          setBottomContainerTotalWin(),
        );
        const roundsBefore = setCurrentBonus().rounds;
        setCurrentBonus({
          ...setCurrentBonus(),
          rounds: bonus.rounds + bonus.roundsPlayed,
        });
        this.updateFreeSpinsAmount(
          setCurrentBonus().currentRound,
          setCurrentBonus().rounds,
        );
        this.gameView.addChild(
          new RetriggerMessage(
            bonus.rounds + bonus.roundsPlayed - roundsBefore,
          ),
        );
        this.setState(SlotMachineState.RETRIGGER);
      } else {
        delayedAction(
          RETRIGGER_MESSAGE_DELAY_DURATION,
          () => {
            setFreeSpinsTotalWin(setFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
            setBottomContainerTotalWin(setBottomContainerTotalWin() + this.nextResult!.bet.result.winCoinAmount);
            eventManager.emit(
              EventTypes.UPDATE_TOTAL_WIN_VALUE,
              setBottomContainerTotalWin(),
            );
            setCurrentBonus({
              ...bonus,
              isActive: true,
              currentRound: setCurrentBonus().currentRound,
              rounds: bonus.rounds + bonus.roundsPlayed,
            });
            this.startFreeSpins(bonus.bonusId);
          },
          () => {
            this.gameView.addChild(new RetriggerMessage(8));
          },
        );
      }
    }
  }

  private onCountUpEnd(): void {
    const mode = setGameMode();

    if (!isFreeSpinMode(mode) && !this.isWinFreeSpins()) {
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult?.balance.settled,
      );
    }

    if (this.isWinFreeSpins()) {
      this.setState(SlotMachineState.JINGLE);
      eventManager.emit(EventTypes.HIDE_COUNT_UP);
      return;
    }

    if (isRegularMode(mode) || isBuyFeatureMode(mode)) {
      setWinAmount(this.nextResult?.bet.result.winCoinAmount);
      setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      eventManager.emit(EventTypes.HIDE_COUNT_UP);
    }

    if (isFreeSpinMode(mode)) {
      setFreeSpinsTotalWin(setFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
      setBottomContainerTotalWin(setBottomContainerTotalWin() + this.nextResult!.bet.result.winCoinAmount);
      if (setBottomContainerTotalWin() > 0) {
        eventManager.emit(
          EventTypes.UPDATE_TOTAL_WIN_VALUE,
          setBottomContainerTotalWin(),
        );
      }
    }
    if (isFreeRoundBonusMode(mode)) {
      setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      setFreeRoundsTotalWin(setBottomContainerTotalWin() + this.nextResult!.bet.result.winCoinAmount);
      setBottomContainerTotalWin(setBottomContainerTotalWin() + this.nextResult!.bet.result.winCoinAmount);
      if (setBottomContainerTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
      }
    }
    this.setState(SlotMachineState.IDLE);
  }

  private onReelsStopped(): void {
    this.onSpinStop();
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
  }

  public setResult(result: ISettledBet): void {
    const spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    const newResult = {
      ...result,
      bet: {
        ...result.bet,
        result: {
          ...result.bet.result,
          spinResult,
        },
        data: {
          ...result.bet.data,
          features: {
            ...result.bet.data.features,
            cascade: result.bet.data.features.cascade.filter((cascade) => cascade.winPositions.length > 0),
          },
        },
      },
    };
    this.nextResult = newResult;
    if (!isFreeSpinMode(setGameMode())) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.placed);
    }
    if (isFreeSpinMode(setGameMode()) || isFreeRoundBonusMode(setGameMode())) {
      const bonus = setCurrentBonus();
      bonus.currentRound += 1;
      setCurrentBonus(bonus);
      if (isFreeSpinMode(setGameMode())) {
        this.updateFreeSpinsAmount(
          setCurrentBonus().currentRound,
          setCurrentBonus().rounds,
        );
      }
      if (isFreeRoundBonusMode(setGameMode())) {
        this.updateFreeRoundsAmount(
          setCurrentBonus().currentRound,
          setCurrentBonus().rounds,
        );
      }
    }
    if (!isFreeSpinMode(setGameMode())) {
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult?.balance.placed,
      );
    }
  }

  public onSpinStop(): void {
    if (setIsErrorMessage()) {
      this.setState(SlotMachineState.IDLE);
      setIsSpinInProgress(false);
      setIsErrorMessage(false);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    } else {
      this.isSpinInProgressCallback();
      this.setState(SlotMachineState.WINNING);
    }
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x].slots[y];
  }

  public getSlotById(id: number): Slot | null {
    const slot = this.getSlotAt(
      id % REELS_AMOUNT,
      Math.floor(id / REELS_AMOUNT),
    );
    return slot;
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    this.application.renderer.resize(width, height);
  }

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(
      EventTypes.DISABLE_PAY_TABLE,
      isFreeSpinMode(setGameMode()) ? false : state === SlotMachineState.IDLE,
    );
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private hasCascadeWin() {
    return this.nextResult!.bet.data.features.cascade.some(
      (cascade) => cascade.winPositions.length > 0,
    );
  }

  private nextCascade(id: number): void {
    const { cascade } = this.nextResult!.bet.data.features;
    if (id > cascade.length) {
      this.checkScatterCoinWin();
    } else {
      const resetAnimation = this.reelsContainer.createResetReelsAnimation(
        cascade[id - 1].winPositions,
      );
      resetAnimation.addOnComplete(() => {
        const spinResult = this.reelsContainer.getCurrentSpinResult();
        if (id < cascade.length) {
          const { winAmounts, multiplier } = cascade[id];
          const prevWin = cascade.reduce((sum, current, index) => {
            return index >= id
              ? sum
              : sum + current.winAmounts.reduce((x, y) => x + y, 0);
          }, 0);
          eventManager.emit(EventTypes.UPDATE_MULTIPLIER_VALUE, multiplier);
          eventManager.emit(
            EventTypes.START_WIN_ANIMATION,
            spinResult,
            cascade[id],
            id,
          );
          eventManager.emit(
            EventTypes.START_COUNT_UP,
            prevWin,
            prevWin + winAmounts.reduce((x, y) => x + y, 0),
            id,
          );
        } else {
          this.checkScatterCoinWin();
        }
      });
      resetAnimation.start();
    }
  }

  private checkScatterCoinWin(): void {
    const isBigWinStart =
      (setGameMode() === GameMode.ANGEL ||
        isRegularMode(setGameMode())) &&
      getWinStage(this.nextResult!) >= WinStages.BigWin;
    if (this.isWinFreeSpins()) {
      const positions = this.nextResult!.paylines.slice(-1).pop()!.winPositions;
      if (isBigWinStart) {
        eventManager.emit(
          EventTypes.START_SCATTER_WIN_ANIMATION,
          this.reelsContainer.getCurrentSpinResult(),
          positions,
        );
        const winAnimation = this.gameView.winCountUpMessage.getBigWinAnimation(
          this.nextResult!,
        );
        winAnimation.addOnComplete(() => this.checkMultiplier());
        winAnimation.addOnSkip(() => this.checkMultiplier());
        winAnimation.start();
      } else {
        delayedAction(
          1500,
          () => {
            this.checkMultiplier();
          },
          () => {
            const cascadeWinAmount = this.nextResult!.bet.data.features.cascade.reduce(
              (acc, cascade) => {
                return (
                  acc +
                  cascade.winAmounts.reduce((sum, amount) => sum + amount, 0)
                );
              },
              0,
            );
            const winAmount =
              this.nextResult!.bet.data.features.scatterWins.reduce(
                (acc, current) => {
                  return acc + (current.rewards[0].multiplier || 0);
                },
                0,
              ) * this.nextResult!.bet.coinAmount;
            eventManager.emit(
              EventTypes.COUNT_UP_SCATTER_WIN_AMOUNT,
              cascadeWinAmount,
              winAmount,
            );
            eventManager.emit(
              EventTypes.START_SCATTER_WIN_ANIMATION,
              this.reelsContainer.getCurrentSpinResult(),
              positions,
            );
          },
        );
      }
    } else if (isBigWinStart) {
      const winAnimation = this.gameView.winCountUpMessage.getBigWinAnimation(
        this.nextResult!,
      );
      winAnimation.addOnComplete(() => this.checkMultiplier());
      winAnimation.addOnSkip(() => this.checkMultiplier());
      winAnimation.start();
    } else {
      this.checkMultiplier();
    }
  }

  private checkMultiplier(): void {
    const { cascade, multiplier } = this.nextResult!.bet.data.features;
    const isBigWin = getWinStage(this.nextResult!) >= WinStages.BigWin;
    if (
      isMultipliersAvailable(setGameMode()) &&
      this.nextResult!.bet.result.winCoinAmount > 0
    ) {
      let sum = cascade.reduce(
        (sum, elem) => sum + elem.winAmounts.reduce((x, y) => x + y, 0),
        0,
      );
      if (this.isWinFreeSpins()) {
        sum +=
          this.nextResult!.bet.data.features.scatterWins.reduce(
            (acc, current) => {
              return acc + (current.rewards[0].multiplier || 0);
            },
            0,
          ) * this.nextResult!.bet.coinAmount;
      }
      delayedAction(
        MULTIPLIER_MOVE_TIME,
        () => {
          if (isBigWin) {
            const winAnimation = this.gameView.winCountUpMessage.getBigWinAnimation(
              this.nextResult!,
            );
            winAnimation.addOnComplete(() => this.onCascadeEnd());
            winAnimation.addOnSkip(() => this.onCascadeEnd());
            winAnimation.start();
          } else {
            delayedAction(
              1500,
              () => this.onCascadeEnd(),
              () =>
                eventManager.emit(
                  EventTypes.START_COUNT_UP,
                  sum,
                  sum * multiplier,
                  -1,
                ),
            );
          }
        },
        () => eventManager.emit(EventTypes.MOVE_MULTIPLIER),
      );
    } else {
      this.onCascadeEnd();
    }
  }

  private onCascadeEnd(): void {
    const winStage = getWinStage(this.nextResult!);
    if (winStage < WinStages.BigWin) {
      eventManager.emit(EventTypes.HIDE_COUNT_UP);
    }
    eventManager.emit(EventTypes.COUNT_UP_END);
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE ||
      setIsFreeSpinsWin() ||
      setIsContinueAutoSpinsAfterFeature(),
    );
    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }
      if (isFreeSpinMode(setGameMode())) {
        if (
          setCurrentBonus().isActive &&
          setCurrentBonus().rounds === setCurrentBonus().currentRound &&
          !this.isWinFreeSpins()
        ) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          this.endFreeSpins();
        } else {
          this.skipAnimations();
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
            setCurrentBonus().currentRound === 0 ? 0 : 500,
          );
        }
      } else if (isFreeRoundBonusMode(setGameMode())) {
        this.miniPayTableContainer.setSpinResult(
          this.reelsContainer.getCurrentSpinResult(),
        );
        if (setCurrentBonus().currentRound === setCurrentBonus().rounds || !setCurrentBonus().isActive) {
          this.endFreeRoundBonus();
          return;
        }
      } else if (isRegularMode(setGameMode()) && !setIsFreeSpinsWin()) {
        // if (setGameMode() === GameMode.REGULAR) {
        //   betResult.bet.data.bonuses.push({
        //     ...(setCurrentBonus() as UserBonus),
        //     isActive: true,
        //     gameMode: GameMode.FREE_ROUND_BONUS,
        //     currentRound: 0,
        //     rounds: 5,
        //     totalWinAmount: 0,
        //     isFreeBet: true,
        //     coinAmount: 1,
        //     coinValue: 100,
        //     id: '6a3a99d5-8988-488a-8af3-1d5ed72716f1',
        //     bonusId: '6a3a99d5-8988-488a-8af3-1d5ed72716f1',
        //   });
        // }
        if (this.nextResult) {
          const frbBonus = this.nextResult.bet.data.bonuses.find((e) => e.isFreeBet);
          if (frbBonus && frbBonus.status !== BonusStatus.SETTLED && setGameMode() === GameMode.REGULAR) {
            eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
            setCurrentBonus({
              ...frbBonus,
              gameMode: GameMode.FREE_ROUND_BONUS,
              rounds: frbBonus.rounds,
              isActive: true,
              currentRound: 0,
              coinAmount: frbBonus.coinAmount,
              coinValue: frbBonus.coinValue,
            });
            setBottomContainerTotalWin(0);
            setFreeRoundsTotalWin(0);
            setIsTransitionStarted(true);
            eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
              mode: GameMode.FREE_ROUND_BONUS,
              reelPositions: setUserLastBetResult().result.reelPositions,
              reelSetId: setUserLastBetResult().reelSetId,
              callback: this.startFreeroundBonus.bind(this)
            });
          }
        }
        this.miniPayTableContainer.setSpinResult(
          this.reelsContainer.getCurrentSpinResult(),
        );
      }
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
      return;
    }
    if (state === SlotMachineState.RETRIGGER) {
      delayedAction(RETRIGGER_MESSAGE_DELAY_DURATION, () => {
        this.setState(SlotMachineState.IDLE);
      });
      return;
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.isWinFreeSpins()) {
        const positions = this.nextResult!.paylines.slice(-1).pop()!
          .winPositions;
        delayedAction(
          1500,
          () => {
            this.handleFreeSpinWin();
          },
          () => {
            AudioHowl.play({ type: ISongs.SFX_WIN_FeatureTrigger });
            eventManager.emit(
              EventTypes.START_SCATTER_WIN_ANIMATION,
              this.reelsContainer.getCurrentSpinResult(),
              positions,
            );
          },
        );
      } else {
        this.setState(SlotMachineState.IDLE);
      }
      return;
    }
    if (state === SlotMachineState.WINNING) {
      if (this.hasCascadeWin()) {
        eventManager.emit(
          EventTypes.START_WIN_ANIMATION,
          this.nextResult!.bet.result.spinResult,
          this.nextResult!.bet.data.features.cascade[0],
          0,
        );
        const {
          winAmounts,
          multiplier,
        } = this.nextResult!.bet.data.features.cascade[0];
        eventManager.emit(EventTypes.UPDATE_MULTIPLIER_VALUE, multiplier);
        eventManager.emit(
          EventTypes.START_COUNT_UP,
          0,
          winAmounts.reduce((x, y) => x + y, 0),
          0,
        );
      } else {
        this.checkScatterCoinWin();
      }
      return;
    }
    if (state === SlotMachineState.ANGEL_FS_START) {
      delayedAction(
        1800,
        () => this.setState(SlotMachineState.IDLE),
        () => eventManager.emit(EventTypes.START_ANGEL_ATTACK),
      );
      return;
    }
    if (state === SlotMachineState.DEVIL_FS_START) {
      delayedAction(
        1800,
        () => this.setState(SlotMachineState.IDLE),
        () => eventManager.emit(EventTypes.START_DEVIL_ATTACK),
      );
      return;
    }

    if (state === SlotMachineState.ANGEL_AND_DEVIL_FS_START) {
      delayedAction(
        1800,
        () => this.setState(SlotMachineState.IDLE),
        () => {
          if (setPrevGameMode() !== GameMode.DEVIL) {
            eventManager.emit(EventTypes.START_DEVIL_ATTACK);
          }
          if (setPrevGameMode() !== GameMode.ANGEL) {
            eventManager.emit(EventTypes.START_ANGEL_ATTACK);
          }
        },
      );
    }
  }

  private startFreeroundBonus(): void {
    setIsFreeSpinsWin(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds - setCurrentBonus().currentRound);
    setCoinValue(setCurrentBonus().coinValue);
    setCoinAmount(setCurrentBonus().coinAmount);
    setBetAmount(setCurrentBonus().coinAmount * setSlotConfig().lineSets[0].coinAmountMultiplier);
    eventManager.emit(EventTypes.UPDATE_BET);
    setIsSlotBusy(false);

    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    if (setBottomContainerTotalWin() > 0) {
      setTimeout(() => {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
      }, 0);
    }
    eventManager.once(EventTypes.START_FREE_ROUND_BONUS, () => {
      this.setState(SlotMachineState.IDLE);
    });
    if (setCurrentBonus().rounds - setCurrentBonus().currentRound === 0) {
      this.setState(SlotMachineState.IDLE);
    } else if (!setFreeRoundsBonus().isActive || !setFreeRoundsBonus().currentRound) {
      PopupController.the.openPopup(PopupTypes.FREE_ROUNDS);
    } else {
      setCurrentBonus({ ...setCurrentBonus(), isActive: true });
      eventManager.emit(EventTypes.START_FREE_ROUND_BONUS);
    }
  }

  private endFreeRoundBonus(): void {
    setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive: false });
    setCurrentBonus({ ...setCurrentBonus(), isActive: false });
    PopupController.the.openPopup(PopupTypes.FREE_ROUNDS_END);
    eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);

    eventManager.once(EventTypes.END_FREE_ROUND_BONUS, () => {
      setBrokenGame(false);
      setIsTransitionStarted(true);
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.REGULAR,
        reelPositions: setUserLastBetResult().result.reelPositions,
        reelSetId: setUserLastBetResult().reelSetId,
      });
    });
  }
}

export default SlotMachine;
